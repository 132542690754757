'use client'

import compose from 'lodash/flowRight'
import { NavigationProgressBar } from 'pattern-library/src/basicUI/NavigationProgressBar'
import { withAmplify } from 'pattern-library/src/hoc/withAmplify'
import { withLoginState } from 'pattern-library/src/hoc/withLoginState'
import React from 'react'
import withAppSync from 'src/hoc/withAppSync'
import withAuthenticatedRoutes from 'src/hoc/withAuthenticatedRoutes'
import withLoggedInUserInfo from 'src/hoc/withLoggedInUserInfo'
import withLoggedInUserPermissions from 'src/hoc/withLoggedInUserPermissions'
import withSnackbar from 'src/hoc/withSnackbar'
import withSubdomainRedirection from 'src/hoc/withSubdomainRedirection'
import withTheme from 'src/hoc/withTheme'
import { useAnalytics } from 'src/hooks/useAnalytics'
import useConsoleVersion from 'src/hooks/useConsoleVersion'
import useMultipleSubdomainAuthentication from 'src/hooks/useMultipleSubdomainAuthentication'

type AppProps = {
  children: React.ReactNode
}

const Provider: React.FC<AppProps> = ({ children }) => {
  useAnalytics()
  useMultipleSubdomainAuthentication()
  useConsoleVersion()

  const { host } = window.location
  if (host.startsWith('www.')) {
    window.location.host = host.replace('www.', '')
  }

  // return React.cloneElement(children as React.ReactElement, { pageProps, ...hocProps })
  return (
    <>
      {children}
      <NavigationProgressBar />
    </>
  )
}

export const AppProvider = compose(
  // must be placed before any rendered components so that the theme and proper logo is applied to them
  withTheme,
  // --------------
  withAmplify,
  withAppSync,
  withLoginState,
  withAuthenticatedRoutes, // must come after withLoginState to get the login status, app type, and theme palette
  withLoggedInUserInfo, // must come after withLoginState to get the login status
  withLoggedInUserPermissions, // must come after withLoggedInUserInfo to get the user email
  withSnackbar,
  withSubdomainRedirection,
)(Provider)
