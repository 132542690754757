// import hotjarPlugin from './analytics-plugin-hotjar'
import segmentAnalyticsPLugin from '@analytics/segment'
import Analytics from 'analytics'

const getPlugins = () => {
  const pluginList: Record<string, unknown>[] = []
  if (process.env.NEXT_PUBLIC_SEGMENT_TRACKING_ID) {
    pluginList.push(segmentAnalyticsPLugin({ writeKey: process.env.NEXT_PUBLIC_SEGMENT_TRACKING_ID }))
  }
  return pluginList
}

const analytics = Analytics({
  app: 'sho-app',
  plugins: getPlugins(),
})

export { analytics }
