import { CognitoUser } from 'amazon-cognito-identity-js'
import { FetchUserAttributesOutput, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth'
import useIsMounted from 'ismounted'
import { loginStatusContext } from 'pattern-library/src/hoc/withLoginState'
import { FC, useContext, useEffect, useState } from 'react'

import { userContext } from '../utils/context-utils'

/*
 * The following interface extends the CognitoUser type because it has issues
 * (see github.com/aws-amplify/amplify-js/issues/4927). Eventually (when you
 * no longer get an error accessing a CognitoUser's 'attribute' property) you
 * will be able to use the CognitoUser type instead of CognitoUserExt.
 */
export interface CognitoUserExt extends CognitoUser {
  attributes: FetchUserAttributesOutput
}

const withLoggedInUserInfo = (Component: FC) => {
  const MyComp = props => {
    const [user, setUser] = useState<FetchUserAttributesOutput | undefined>()
    const loginStatus = useContext(loginStatusContext)
    // need to prevent updates to happen if the component is unMounted to avoir memory leaks.
    // https://www.debuggr.io/react-update-unmounted-component/
    const isMounted = useIsMounted()
    // Check if user is signed in or not when load the app.

    useEffect(() => {
      const fonc = async () => {
        try {
          const result = await fetchAuthSession({ forceRefresh: true })
          const attributes = await fetchUserAttributes()
          if (result.tokens && attributes) {
            setUser(attributes)
          }
        } catch (err) {
          console.log(
            'cannot get cognito user in withLoggedInUserInfo. The user is either not logged in or an error occured:',
            err,
          )
        }
      }
      fonc()
    }, [isMounted, loginStatus])

    return (
      <userContext.Provider value={user}>
        <Component {...props} />
      </userContext.Provider>
    )
  }
  MyComp.displayName = 'withLoggedInUserInfo'
  return MyComp
}

export default withLoggedInUserInfo
