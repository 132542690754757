'use client'

// links Apollo cache with chosen custom cache
// storage library
import { WaitingWithData } from 'app/_components/WaitingWithData'
import { FC } from 'react'

// utils

// DOC
// https://medium.com/@guillac124/create-your-custom-apollo-client-for-aws-appsync-to-use-hooks-2d5cbce29db5
// https://stackoverflow.com/questions/60804372/proper-way-to-setup-awsappsyncclient-apollo-react
// https://dev.to/danielbayerlein/migrate-apollo-from-v2-to-v3-in-conjunction-with-aws-appsync-16c0
// https://stackoverflow.com/questions/67368064/what-is-the-difference-betweeen-aws-sdk-clients-appsync-and-aws-appsync

// PLACE this hoc AFTER the HOC withLoginState

const withSubdomainRedirection = (Component: FC) => {
  const MyComp = props => {
    const { host, pathname } = window.location
    const localhostWithExtraSubdomain = host.includes('localhost:3000') && host.split('.').length > 1
    const appDevWithExtraSubdomain = host.includes('app-dev.pubgen.ai') && host.split('.').length > 3
    const appStagingWithExtraSubdomain = host.includes('app-staging.pubgen.ai') && host.split('.').length > 3
    const appWithExtraSubdomain = host.includes('app.pubgen.ai') && host.split('.').length > 3
    const needsToBeRedirected =
      localhostWithExtraSubdomain || appDevWithExtraSubdomain || appStagingWithExtraSubdomain || appWithExtraSubdomain
    if (needsToBeRedirected) {
      let callBackUrl = ''
      if (localhostWithExtraSubdomain) {
        callBackUrl = 'http://localhost:3000'
      } else if (appDevWithExtraSubdomain) {
        callBackUrl = 'https://app-dev.pubgen.ai'
      } else if (appStagingWithExtraSubdomain) {
        callBackUrl = 'https://app-staging.pubgen.ai'
      } else if (appWithExtraSubdomain) {
        callBackUrl = 'https://app.pubgen.ai'
      } else {
        console.error('withSubdomainRedirection', 'no valid subdomain')
      }
      return (
        <WaitingWithData
          message="PubGen.AI CMS no longer uses subdomains."
          secondaryMessage="Don't worry, your data is safe! We'll take you to the right place."
          buttonText={'Go to PubGen.AI CMS'}
          buttonCallback={() => window.open(`${callBackUrl}${pathname}`, '_self')}
          loadingIndicatorIsDisabled={true}
        />
      )
    }

    return <Component {...props} />
  }
  MyComp.displayName = 'withSubdomainRedirection'
  return MyComp
}

export default withSubdomainRedirection
