'use client'

import { useServerInsertedHTML } from 'next/navigation'
import { getCssText } from 'pattern-library/src/stitches.config'
import React, { useRef } from 'react'

// doc: https://github.com/stitchesjs/stitches/issues/1109
// => But this leads to an issue with Stitches being injected twice on the client. Once in the head, another time in the body.
// https://github.com/vercel/next.js/discussions/49354
export function StitchesRegistry({ children }: { children: React.ReactNode }) {
  const isServerInserted = useRef(false)

  useServerInsertedHTML(() => {
    if (!isServerInserted.current) {
      isServerInserted.current = true
      return <style id="stitches" dangerouslySetInnerHTML={{ __html: getCssText() }} />
    }
  })

  return <>{children}</>
}
