import * as Portal from '@radix-ui/react-portal'
import useIsMounted from 'ismounted'
import { Snackbar } from 'pattern-library/src/basicUI/Snackbar'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { snackbarContext } from '../utils/context-utils'

type SnackbarProps = {
  autoHideDuration?: number
  message: string
  type?: 'success' | 'alert' | 'neutral' | 'warning'
}

export type OpenSnackbarType = (props: SnackbarProps) => void

const withSnackbar = (Component: FC) => {
  const MyComp = props => {
    const isMounted = useIsMounted()
    const [messages, setMessages] = useState<
      { id: string; message: SnackbarProps['message']; type: SnackbarProps['type']; autoHideDuration: number }[]
    >([])
    const timerIds = useRef<NodeJS.Timeout[]>([])
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

    const openSnackbar: OpenSnackbarType = ({
      autoHideDuration: _autoHideDuration,
      message = '',
      type = 'neutral',
    }: SnackbarProps) => {
      const autoHideDuration = _autoHideDuration || type === 'alert' ? 10000 : 5000

      if (isMounted) {
        const id = uuidv4()
        setIsSnackbarOpen(true)
        setMessages(prevMessages => [
          // To avoid the snackbar from stacking up, we remove the previous messages
          // ...prevMessages,
          {
            id,
            message,
            autoHideDuration,
            type,
          },
        ])
        const timeout = setTimeout(() => {
          setMessages(prevMessages => prevMessages.filter(m => m.id !== id))
          clearTimeout(timeout)
          timerIds.current = timerIds.current.filter(timerId => timerId !== timeout)
        }, autoHideDuration)
        timerIds.current.push(timeout)
      }
    }

    useEffect(() => {
      return () => {
        // Clear timeouts when the component is unmounted
        if (timerIds.current.length > 0) {
          timerIds.current.forEach(timerId => clearTimeout(timerId))
        }
      }
    }, [])

    const removeMessages = useCallback(() => {
      setMessages([])
    }, [])

    // Returns Target component with Snackbar
    return (
      <snackbarContext.Provider value={openSnackbar}>
        <Component {...props} />
        {isSnackbarOpen && (
          <Portal.Root>
            <Snackbar
              key={messages[0]?.id}
              messages={messages}
              open={isSnackbarOpen}
              onCloseSnackbar={removeMessages}
            />
          </Portal.Root>
        )}
      </snackbarContext.Provider>
    )
  }
  MyComp.displayName = 'withSnackbar'
  return MyComp
}

export default withSnackbar
