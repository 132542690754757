import { FC } from 'react'

import { keyframes, styled } from '../stitches.config'
import { Flex } from './Flex'
import { Icon } from './Icon'
import { Text } from './Text'

type SnackbarProps = {
  messages: {
    id: string
    message: string
    type?: 'success' | 'alert' | 'neutral' | 'warning'
    autoHideDuration: number
    showProgress?: boolean
  }[]
  open: boolean
  onCloseSnackbar: () => void
}

export const Snackbar: FC<SnackbarProps> = ({ messages = [], open = false, onCloseSnackbar }) => (
  <SnackbarContainer open={open}>
    <Flex direction="column" gap="5" justify="center" align="center">
      {messages.map(({ message, id, type, autoHideDuration, showProgress = false }) => (
        <SnackbarWrapper type={type} key={id}>
          <Text>{message}</Text>
          <CloseIcon type={type} onClick={onCloseSnackbar} name="close" size={26} />
          {showProgress && <SnackbarProgress css={{ animationDuration: `${autoHideDuration}ms` }} />}
        </SnackbarWrapper>
      ))}
    </Flex>
  </SnackbarContainer>
)

const CloseIcon = styled(Icon, {
  // color: '$gs12',
  // cursor: 'pointer',
  // backgroundColor: '$gs1',
  //   backgroundColor: '$gs4',
  borderRadius: '$3',
  padding: '$1',
  '&:hover': {
    transition: 'background-color 0.2s ease-in-out',
  },
  variants: {
    type: {
      success: {
        backgroundColor: '$suc',
        '&:hover': {
          backgroundColor: '$suc_L',
        },
        color: '$sucText',
        svg: {
          color: '$sucText',
        },
      },
      alert: {
        backgroundColor: '$ale',
        '&:hover': {
          backgroundColor: '$ale_L',
        },
        color: '$aleText',
        svg: {
          color: '$aleText',
        },
      },
      warning: {
        backgroundColor: '$war',
        '&:hover': {
          backgroundColor: '$war_L',
        },
        color: '$warText',
        svg: {
          color: '$warText',
        },
      },
      neutral: {
        backgroundColor: '$gs1',
        '&:hover': {
          backgroundColor: '$gs2',
        },
        color: '$gs12',
        svg: {
          color: '$gs12',
        },
      },
    },
  },
  defaultVariants: {
    type: 'neutral',
  },
})

const loadingKeyframes = keyframes({
  '0%': { maxWidth: 'calc(100% - 2px)' },
  '3%': { borderBottomRightRadius: '0px' },
  '100%': { maxWidth: '0px' },
})

const SnackbarProgress = styled('div', {
  position: 'absolute',
  bottom: -1,
  left: 0,
  right: 0,
  width: '100%',
  height: '4px',
  backgroundColor: '$gs12',
  animation: `${loadingKeyframes} 5s linear`,
  borderBottomLeftRadius: '22px 5px',
  borderBottomRightRadius: '22px 5px',
})

const animateUp = keyframes({
  '0%': {
    bottom: '21px',
    transform: 'translate(-50%, 100%)',
  },
  '100%': {
    bottom: '21px',
    transform: 'translate(-50%,0%)',
  },
})

const SnackbarContainer = styled('div', {
  transition: 'transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms, opacity 300ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  animation: `${animateUp} 300ms forwards cubic-bezier(0, 0, 0.2, 1) 0ms`,
  position: 'fixed',
  display: 'flex',
  left: '50%',
  justifyContent: 'center',
  zIndex: '2147483648', //one more than "radix-ui" dialog
  '@media print': {
    display: 'none',
  },
  variants: {
    open: {
      true: {
        tranform: 'translate(-50%, 0)',
        zIndex: 1401,
        opacity: 1,
      },
      false: {
        transform: 'translate(-50%, calc(150% + 21px))',
        zIndex: -1,
        opacity: 0,
      },
    },
  },
  defaultVariants: {
    open: 'false',
  },
})

const SnackbarWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  zIndex: 10,
  maxWidth: '600px',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  textAlign: 'center',
  gap: '$6',
  justifyContent: 'center',
  padding: '14px',
  borderRadius: '7px',
  backgroundColor: '$gs1',
  border: '1px solid $gs12',
  px: '$4',
  py: '$4',
  span: {
    mb: 0,
  },
  variants: {
    type: {
      success: {
        backgroundColor: '$suc',
        color: '$sucText',
        svg: {
          color: '$sucText',
        },
      },
      alert: {
        backgroundColor: '$ale',
        color: '$aleText',
        svg: {
          color: '$aleText',
        },
      },
      warning: {
        backgroundColor: '$war',
        color: '$warText',
        svg: {
          color: '$warText',
        },
      },
      neutral: {
        backgroundColor: '$gs1',
        color: '$gs12',
        svg: {
          color: '$gs12',
        },
      },
    },
  },
  defaultVariants: {
    type: 'neutral',
  },
})
