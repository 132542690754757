import { Noto_Sans, Noto_Serif } from 'next/font/google'
import { globalCss, globalCssObject, pubgenDarkTheme, pubgenLightTheme } from 'pattern-library/src/stitches.config'
import { FC, useEffect, useState } from 'react'

import { themeContext } from '../utils/context-utils'

// https://nextjs.org/docs/pages/building-your-application/optimizing/fonts#google-fonts
//https://stackoverflow.com/questions/74887314/using-multiple-fonts-across-pages-in-next-js
const NOTO_SERIF = Noto_Serif({
  weight: ['400', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
})
const NOTO_SANS = Noto_Sans({
  weight: ['400', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
})

export type THEME_PALETTE = 'dark' | 'light'
export const LOCAL_STORAGE_THEME_KEY = 'themePalette'
const THEME_CONTAINER_ELEMENT_ID = 'THEME_PALETTE_STITCHES'

const withTheme = (Component: FC) => {
  const MyComp = props => {
    const [themePalette, setThemePalette] = useState<THEME_PALETTE>(() => {
      if (typeof window !== 'undefined') {
        const themePalette = localStorage.getItem(LOCAL_STORAGE_THEME_KEY)
        if (themePalette === 'dark') {
          return 'dark'
        } else {
          return 'light'
        }
      }
      return 'light'
    })
    useEffect(() => {
      globalCss({
        ...globalCssObject,
        // '@font-face': [
        //   {
        //     fontFamily: 'IBM Plex Sans',
        //     src: 'url("https://fonts.gstatic.com/s/ibmplexsans/v14/zYX-KVElMYYaJe8bpLHnCwDKhdTuF6ZJW9XjDg.woff2") format("woff2")',
        //     fontStyle: 'normal',
        //     fontWeight: '400',
        //     fontDisplay: 'swap',
        //   },
        //   {
        //     fontFamily: 'IBM Plex Sans',
        //     src: 'url("https://fonts.gstatic.com/s/ibmplexsans/v14/zYX7KVElMYYaJe8bpLHnCwDKhdTmrINcePfuJGl18QRY.woff2") format("woff2")',
        //     fontStyle: 'italic',
        //     fontWeight: '700',
        //     fontDisplay: 'swap',
        //   },
        //   {
        //     fontFamily: 'IBM Plex Sans',
        //     src: 'url("https://fonts.gstatic.com/s/nunitosans/v3/pe0qMImSLYBIv1o4X1M8cfe6.ttf") format("woff2")',
        //     fontStyle: 'normal',
        //     fontWeight: '400',
        //     fontDisplay: 'swap',
        //   },
        //   {
        //     fontFamily: 'IBM Plex Sans',
        //     src: 'url("https://fonts.gstatic.com/s/ibmplexsans/v14/zYX9KVElMYYaJe8bpLHnCwDKjWr7AIFsdP3pBms.woff2") format("woff2")',
        //     fontStyle: 'normal',
        //     fontWeight: '700',
        //     fontDisplay: 'swap',
        //   },
        // ],
        body: {
          ...globalCssObject.body,
          backgroundColor: '$gs1',
          '#nprogress': {
            '.bar': {
              backgroundColor: '$sec',
            },
            '.peg': {
              boxShadow: '0 0 10px $color$sec, 0 0 5px $colors$sec',
            },
          },
        },
        '#__next': {
          height: '100%',
        },
      })()
    }, [])

    useEffect(() => {
      if (themePalette === 'dark') {
        localStorage.setItem(LOCAL_STORAGE_THEME_KEY, 'dark')
        window.document.body.classList.add('pubgen-dark')
        window.document.body.classList.remove('pubgen-light')
        window.document.getElementById(THEME_CONTAINER_ELEMENT_ID)?.classList.add(pubgenDarkTheme)
        window.document.getElementById(THEME_CONTAINER_ELEMENT_ID)?.classList.remove(pubgenLightTheme)
      } else {
        localStorage.setItem(LOCAL_STORAGE_THEME_KEY, 'light')
        window.document.body.classList.add('pubgen-light')
        window.document.body.classList.remove('pubgen-dark')
        window.document.getElementById(THEME_CONTAINER_ELEMENT_ID)?.classList.add(pubgenLightTheme)
        window.document.getElementById(THEME_CONTAINER_ELEMENT_ID)?.classList.remove(pubgenDarkTheme)
      }
    }, [themePalette])

    return (
      <>
        <style jsx global>{`
          html {
            --font-family-nunito-sans-serif: ${NOTO_SANS.style.fontFamily};
            --font-family-nunito-serif: ${NOTO_SERIF.style.fontFamily};
          }
        `}</style>
        <div
          className={themePalette === 'dark' ? pubgenDarkTheme : pubgenLightTheme}
          style={{ height: '100%' }}
          id={THEME_CONTAINER_ELEMENT_ID}
        >
          <themeContext.Provider value={{ themePalette, setThemePalette }}>
            <Component {...props} />
          </themeContext.Provider>
        </div>
      </>
    )
  }
  MyComp.displayName = 'withTheme'
  return MyComp
}

export default withTheme
