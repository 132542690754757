import { usePathname } from 'next/navigation'
import React from 'react'

import { analytics } from '../utils/analytics'

export const useAnalytics = () => {
  const pathname = usePathname()
  React.useEffect(() => {
    // Fire initial page view
    analytics.page()
  }, [pathname])

  return analytics
}
