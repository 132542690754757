import { useRouter } from 'next-nprogress-bar'
import { usePathname } from 'next/navigation'
import { loginStatusContext } from 'pattern-library/src/hoc/withLoginState'
import { FC, useContext } from 'react'

import { WaitingWithData } from '../../app/_components/WaitingWithData'
import { authenticatedPaths, unauthenticatedPaths } from '../utils/route-utils'

const withAuthenticatedRoutes = (Component: FC) => {
  const MyComp = props => {
    const loginStatus = useContext(loginStatusContext)
    const router = useRouter()
    const pathname = usePathname()

    if (loginStatus === undefined) {
      console.error(
        'withProtecteRoutes does not have the props loginStatus, and thus cannot determine if the page is protected or not. Has this HOC been moved before withLoginStatus?',
      )
      return (
        <WaitingWithData message="Login status undertermined. If problem persists, please contact your system administrator." />
      )
    }

    if (loginStatus === 'loading') {
      return <WaitingWithData message="Authentication in progress" />
    }

    // If a user is logged out but is trying to access a page that isn't public, redirect to login page
    if (pathname && loginStatus === 'loggedOut' && !Object.values(unauthenticatedPaths).includes(pathname as any)) {
      // The onlhy exception is if the user is trying to access the new password page.
      if (pathname?.includes(unauthenticatedPaths.newPasswordRequired)) {
        return <Component {...props} />
      } else {
        // This will bypass the no-found path of next-js for unauthenticated pages
        // set in localstorage the redirect path
        localStorage.setItem('redirectPath', pathname)
        router.push(unauthenticatedPaths.login)
        return <WaitingWithData message="Authentication in progress" />
      }
    }

    // If a user is logged in but is trying to access a public page, redirect to workspaces
    if (pathname && loginStatus === 'loggedIn' && Object.values(unauthenticatedPaths).includes(pathname as any)) {
      router.push(authenticatedPaths.workspaceHome)
      return <WaitingWithData message="Redirecting to workspaces" />
    }

    // if localstorage has a redirect path and user is logged in, redirect to it
    const redirectPath = localStorage.getItem('redirectPath')
    if (redirectPath && loginStatus === 'loggedIn') {
      // remove the redirect path from localstorage
      localStorage.removeItem('redirectPath')
      if (redirectPath === authenticatedPaths.workspaceHome) {
        router.refresh()
      } else {
        router.push(redirectPath)
      }
      return <WaitingWithData message="Loading" />
    }

    if (loginStatus) return <Component {...props} />
  }
  MyComp.displayName = 'withAuthenticatedRoutes'
  return MyComp
}

export default withAuthenticatedRoutes
