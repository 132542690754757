import queryString from 'query-string'
import { useEffect } from 'react'

// If there is an issue here, consider using the library: https://github.com/astoilkov/use-local-storage-state
const useMultipleSubdomainAuthentication = (): void => {
  // If there is a query string with key cognitoUserStorage, then populate its content into localStorage
  useEffect(() => {
    if (window.location.search) {
      const parsed = queryString.parse(window.location.search)
      if (typeof parsed?.cognitoUserStorage === 'string') {
        const userStorage = JSON.parse(parsed.cognitoUserStorage)
        Object.keys(userStorage).forEach(key => {
          localStorage.setItem(key, userStorage[key])
        })
      }
    }
  }, [])
}
export default useMultipleSubdomainAuthentication
