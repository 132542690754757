import { from } from '@apollo/client'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

// creating link to pass to
export const createLink = auth =>
  from([
    // createAuthLink and createSubscriptionHandshakeLink are required to connect to AppSync
    createAuthLink({
      url: `https://appsync-${process.env.NEXT_PUBLIC_STAGE}.pubgen.ai/graphql`,
      region: process.env.NEXT_PUBLIC_AWS_REGION,
      auth,
    }),
    createSubscriptionHandshakeLink({
      url: `https://appsync-${process.env.NEXT_PUBLIC_STAGE}.pubgen.ai/graphql`,
      region: process.env.NEXT_PUBLIC_AWS_REGION,
      auth,
    }),
  ])
