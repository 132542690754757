import Image from 'next/image'
import { FC } from 'react'

import { styled } from '../stitches.config'
import { Button } from './Button'
import { LoadingIndicator } from './LoadingIndicator'
import { Text } from './Text'

export type WaitingProps = {
  message?: string
  secondaryMessage?: string
  children?: React.ReactNode
  logoDetails?: {
    logoSrc: any
    logoWidth: number
    logoHeight: number
  } | null
  buttonText?: string
  buttonCallback?: () => void
  loadingIndicatorIsDisabled?: boolean
}

export const Waiting: FC<WaitingProps> = ({
  logoDetails,
  message = '',
  secondaryMessage,
  buttonText,
  buttonCallback,
  loadingIndicatorIsDisabled,
}) => (
  <>
    <BackgroundContainer>
      <div>
        {logoDetails && (
          <Image
            src={logoDetails.logoSrc}
            alt="App Logo"
            height={logoDetails.logoHeight}
            width={logoDetails.logoWidth}
            priority={true}
          />
        )}
      </div>
      {message && (
        <Text variant="body1" css={{ mt: '$6' }}>
          {message}
        </Text>
      )}
      {secondaryMessage && (
        <Text variant="body2" css={{ mt: '$4' }}>
          {secondaryMessage}
        </Text>
      )}
      {buttonText && buttonCallback && (
        <Button
          onClick={buttonCallback}
          label={buttonText}
          css={{
            mt: '$6',
          }}
        />
      )}
    </BackgroundContainer>
    {!loadingIndicatorIsDisabled && (
      <LoadingIndicator
        palette="grayscale"
        size="large"
        css={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
        }}
      />
    )}
  </>
)

const BackgroundContainer = styled('div', {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: '$gs2',
  color: '$gs12',
})
