'use client'

import { Waiting, WaitingProps } from 'pattern-library/src/basicUI/Waiting'
import { FC, useContext } from 'react'

import { themeContext } from '../../src/utils/context-utils'
import { getAppLogo } from '../../src/utils/publication-utils'

export const WaitingWithData: FC<WaitingProps> = props => {
  const theme = useContext(themeContext)
  const themePalette = theme?.themePalette || 'light'

  const logoDetails = getAppLogo({
    themePalette,
    requestedHeight: 54,
  })

  return <Waiting {...props} logoDetails={logoDetails} />
}
