import { useParams } from 'next/navigation'
import { FC, useContext } from 'react'

import { BrandGroup, WorkspaceGroup, useGetUserByDomainQuery } from '../graphql/operations'
import { permissionsContext, userContext } from '../utils/context-utils'
import { buildDomainForGraphQL } from '../utils/publication-utils'

export type WorkspacePermissions = { workspacePermissions: WorkspaceGroup[] | undefined }
export type BrandPermissions = { brandPermissions: BrandGroup[] | undefined }

const withLoggedInUserPermissions = (Component: FC) => {
  const MyComp = props => {
    const params = useParams()
    const brand = params?.brand as string
    const workspace = params?.workspace as string
    const user = useContext(userContext)

    const { data } = useGetUserByDomainQuery({
      fetchPolicy: 'cache-and-network',
      // nextFetchPolicy: 'cache-first',
      variables: {
        domain: buildDomainForGraphQL(workspace),
        username: user?.email || '',
        path: brand,
      },
      skip: !user?.email || !brand || !workspace,
      onError: error => {
        console.error('useGetUserByDomainQuery errror in withLoggedInUserPErmissions:', error)
      },
    })

    const userWorkspacePermission = data?.getWorkspaceByDomain.getWorkspaceUser?.workspaceGroups
    const workspacePermissions: WorkspacePermissions = { workspacePermissions: userWorkspacePermission }

    const brandId = data?.getWorkspaceByDomain.getBrandByPath?.id
    const userBrandPermission = data?.getWorkspaceByDomain.getWorkspaceUser?.brandGroups.find(
      brand => brand.brandId === brandId,
    )?.groups
    const brandPermissions: BrandPermissions = { brandPermissions: userBrandPermission }

    return (
      <permissionsContext.Provider value={{ ...workspacePermissions, ...brandPermissions }}>
        <Component {...props} />
      </permissionsContext.Provider>
    )
  }
  MyComp.displayName = 'withLoggedInUserPermissions'
  return MyComp
}

export default withLoggedInUserPermissions
