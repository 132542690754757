'use client'

import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import useIsMounted from 'ismounted'
import { analytics } from 'next-app/src/utils/analytics'
import posthog from 'posthog-js'
import { FC, createContext, useEffect, useState } from 'react'

export type LOGINSTATUS = 'loggedIn' | 'loggedOut' | 'loading'
export type LoginStatusContextType = LOGINSTATUS | null
export const loginStatusContext = createContext<LoginStatusContextType>(null)

export const withLoginState = (Component: FC) => {
  const MyComp = (props: any) => {
    const [loginStatus, setLoginStatus] = useState<LOGINSTATUS>('loading')
    const isMounted = useIsMounted()

    // Check if user is signed in or not when load the app.
    useEffect(() => {
      const authenticate = async () => {
        try {
          const result = await fetchAuthSession({ forceRefresh: true })
          const userAttributes = await fetchUserAttributes()
          // To check if user is signed in or not
          // https://docs.amplify.aws/javascript/build-a-backend/auth/manage-user-session/#retrieve-a-user-session
          if (result.tokens && userAttributes.email) {
            setLoginStatus('loggedIn')
            analytics.identify(userAttributes.email, {
              ...userAttributes,
              'custom:company': userAttributes['custom:company'] || 'VOID',
            })
            posthog.identify(userAttributes.email, {
              email: userAttributes.email,
            })
          } else {
            console.warn('user is not logged in')
            setLoginStatus('loggedOut')
          }
        } catch (err) {
          if (isMounted) {
            setLoginStatus('loggedOut')
          }
        }
      }
      authenticate()
    }, [isMounted])

    // listens to the global state
    useEffect(() => {
      const listenToGlobalEvents = async () => {
        Hub.listen('auth', data => {
          const { payload } = data
          // console.log('A new auth event has happened: ', data)
          if (payload.event === 'signedIn') {
            if (isMounted) {
              // console.log("a user has signed in!");
              setLoginStatus('loggedIn')
            }
          }
          if (payload.event === 'signedOut') {
            if (isMounted) {
              // console.log("a user has signed out!");
              setLoginStatus('loggedOut')
            }
          }
        })
      }
      listenToGlobalEvents()
    }, [isMounted])

    return (
      <loginStatusContext.Provider value={loginStatus}>
        <Component {...props} />
      </loginStatusContext.Provider>
    )
  }
  MyComp.displayName = 'withLoginState'
  return MyComp
}
