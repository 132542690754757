import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/Users/mathisobadia/work/sho-app/next-app/app/_components/AppProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Polyfill"] */ "/Users/mathisobadia/work/sho-app/next-app/app/polyfill.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/Users/mathisobadia/work/sho-app/next-app/app/posthog.tsx");
;
import(/* webpackMode: "eager" */ "/Users/mathisobadia/work/sho-app/node_modules/material-icons/iconfont/filled.css");
;
import(/* webpackMode: "eager" */ "/Users/mathisobadia/work/sho-app/node_modules/material-icons/iconfont/outlined.css");
;
import(/* webpackMode: "eager", webpackExports: ["StitchesRegistry"] */ "/Users/mathisobadia/work/sho-app/pattern-library/src/basicUI/StitchesRegistry.tsx");
