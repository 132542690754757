import { Amplify, ResourcesConfig } from 'aws-amplify'
import { FC } from 'react'

const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: process.env.NEXT_PUBLIC_USERPOOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.NEXT_PUBLIC_USERPOOL_CLIENT,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: `sho-app-${process.env.NEXT_PUBLIC_STAGE}.auth.us-east-1.amazoncognito.com`,
          scopes: ['email', 'openid', 'phone', 'aws.cognito.signin.user.admin', 'profile'],
          // Callback URL
          redirectSignIn: [`https://${process.env.NEXT_PUBLIC_APP_DOMAIN}`],
          // Sign out URL
          redirectSignOut: [`https://${process.env.NEXT_PUBLIC_APP_DOMAIN}`],
          // 'code' for Authorization code grant,
          // 'token' for Implicit grant
          responseType: 'code',
        },
      },
    },
  },
}

// Documentation: https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
Amplify.configure(amplifyConfig, {
  ssr: true,
})

export const withAmplify = (Component: FC) => {
  const MyComp = (props: any) => <Component {...props} />
  MyComp.displayName = 'withAmplify'
  return MyComp
}
