import React from 'react'

import { CSS, keyframes, styled } from '../stitches.config'

const DEFAULT_SIZE = 'small'
const DEFAULT_PALETTE = 'color'

export interface CardProps {
  /**
   *  Decide the size of the card.
   */
  size?: 'small' | 'large'

  /**
   *  Decide the type of card.
   */
  palette?: 'color' | 'grayscale'

  /**
   *  Set css property.
   */
  css?: CSS

  /**
   * Set className property.
   * @default undefined
   * */
  className?: string
}

export const LoadingIndicator: React.FC<CardProps> = ({
  size = DEFAULT_SIZE,
  palette = DEFAULT_PALETTE,
  css,
  className,
}) => {
  return (
    <LoadingIndicatorContainer css={css} size={size} className={className}>
      <LoadingBarContainer palette={palette}>
        <LoadingBar palette={palette} />
      </LoadingBarContainer>
    </LoadingIndicatorContainer>
  )
}

const borealisBar = keyframes({
  '0%': {
    left: '0%',
    right: '100%',
    width: '0%',
  },
  '10%': {
    left: '0%',
    right: '75%',
    width: '25%',
  },
  '90%': {
    right: '0%',
    left: '75%',
    width: '25%',
  },
  '100%': {
    left: '100%',
    right: '0%',
    width: '0%',
  },
})

// https://codepen.io/stoepke/pen/QOOqGW
const LoadingIndicatorContainer = styled('div', {
  display: 'flex',
  variants: {
    size: {
      small: {
        height: '4px',
      },
      large: {
        height: '10px',
      },
    },
  },
  defaultVariants: {
    size: DEFAULT_SIZE,
  },
})

const LoadingBarContainer = styled('div', {
  position: 'relative',
  width: '100%',
  padding: '1px',
  variants: {
    palette: {
      color: {
        backgroundColor: '$sec_L',
      },
      grayscale: {
        backgroundColor: '$gs5',
      },
    },
  },
  defaultVariants: {
    palette: DEFAULT_PALETTE,
  },
})

const LoadingBar = styled('div', {
  position: 'absolute',
  top: '0',
  right: '100%',
  bottom: '0',
  left: '0',
  width: '0',
  animation: `${borealisBar} 2.5s linear infinite`,
  variants: {
    palette: {
      color: {
        backgroundColor: '$sec_D',
      },
      grayscale: {
        backgroundColor: '$gs8',
      },
    },
  },
  defaultVariants: {
    palette: DEFAULT_PALETTE,
  },
})
